/**  忘记密码 - 头部组件 */
import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { GetUserLimitSwitchEnum } from '@/constants/user'
import { useLimitSwitch } from '@/hooks/features/limit-switch'
import UserTipsInfo from '@/features/user/common/tips-info'
import style from '@/features/recreation/theme/2z/game-reset-title/index.module.css'

type GameTitleType = {
  /** 返回方法 */
  backStatus: () => void
}

function GameTitle2z(props: GameTitleType) {
  const { backStatus } = props
  const popupLimit = useLimitSwitch()

  return (
    <div>
      <div className={style['user-retrive-title-header']}>
        <Icon name="nav_icon_back" className="retrive-icon" onClick={() => backStatus()} />
        <p>{t`user.pageContent.title_03`}</p>
      </div>

      {popupLimit?.pwdLimitSwitch === GetUserLimitSwitchEnum.enable && (
        <UserTipsInfo
          slotContent={
            <p className="mt-0.5">{t`features_user_log_register_modal_component_reset_password_index_gqkcmsrsv1`}</p>
          }
        />
      )}

      {/* <UserChooseVerificationMethod
          tabList={[
            { title: t`user.safety_items_04`, id: 'email' },
            { title: t`user.validate_form_header_02`, id: 'phone' },
          ]}
          method={method}
          choosMethod={handleChoosMethod}
        /> */}
    </div>
  )
}

export default GameTitle2z
